<script>
import RenderAvatars from '/~rec/components/render/render-avatars.vue'
import ChatRoom from '/~rec/components/chat/room/chat-room.vue'
import ChatMenu from '/~rec/components/chat/chat-menu.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useChat } from '/~rec/composables/chat'

export default {
  name: 'rec-chat-popup',
  components: {
    RenderAvatars,
    ChatRoom,
    ChatMenu,
    BaseButton,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  emits: ['click'],
  setup() {
    const { chat, closeRoom } = useChat()

    return { chat, closeRoom }
  },
  computed: {
    isSelected() {
      return this.chat.selectedRoom === this.room
    },
  },
}
</script>

<template>
  <div
    class="w-80 cursor-default rounded-t-xl bg-default shadow-lg"
    @click="$emit('click', $event)"
  >
    <div
      class="flex h-12 items-center rounded-t-xl pl-5 pr-2.5"
      :class="{
        'bg-gray-700 text-primary-content': isSelected,
        'bg-strong': !isSelected,
      }"
    >
      <render-avatars
        class="mx-2.5"
        :class="{
          'pointer-events-none': !room.isMeOwner || !room.isChannel,
          'text-primary': isSelected,
          'text-divider': !isSelected,
        }"
        look="col"
        :users="room.activeMembersWithoutMe"
        @click="() => $refs.chatMenu.editMembers()"
      />
      <span class="mr-5 truncate font-bold">
        {{ room.title }}
      </span>
      <div class="ml-auto flex items-center">
        <chat-menu ref="chatMenu" :room="room" />
        <base-button
          class="mx-[5px] block"
          icon="close"
          @click="closeRoom(room)"
        />
      </div>
    </div>
    <chat-room class="h-96" :room="room" :active="isSelected" />
  </div>
</template>
