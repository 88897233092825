<script>
import { ref, provide } from 'vue'
import DesktopToolbar from './desktop-toolbar.vue'
import ChatWidget from '/~rec/components/chat/chat-widget.vue'
import ChatPopupKeeper from '/~rec/components/chat/popup/chat-popup-keeper.vue'
import DirectorySection from '/~rec/views/directory/directory-root.vue'
import SidebarBanners from '/~rec/views/sidebar-banners/sidebar-banners-root.vue'
import { useRecUi } from '/~rec/composables/ui'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-layout-desktop',
  components: {
    DesktopToolbar,
    ChatPopupKeeper,
    ChatWidget,
    DirectorySection,
    SidebarBanners,
  },
  setup() {
    const hasShadow = ref(false)
    const { showSidebar } = useRecUi()
    const { features } = useRecProfile()

    provide('hasShadow', hasShadow)

    function onScroll(e) {
      hasShadow.value = e.target && e.target.scrollTop > 0
    }

    return {
      hasShadow,
      onScroll,
      showSidebar,
      features,
    }
  },
}
</script>

<template>
  <div class="flex h-full w-full">
    <div class="grow bg-dark" />
    <div class="flex h-full w-full max-w-full shrink-0 grow overflow-hidden">
      <desktop-toolbar class="shrink-0 overflow-y-auto" />
      <div
        class="relative flex h-full w-full flex-col rounded-tl-3xl sm:overflow-hidden md:rounded-t-3xl"
      >
        <div
          class="pointer-events-none absolute top-0 z-10 h-[30px] w-full rounded-inherit shadow-inner transition duration-300"
          :class="{
            'opacity-100': hasShadow,
            'opacity-0': !hasShadow,
          }"
        />

        <div
          id="rec-wall"
          class="grow rounded-inherit border sm:h-full sm:overflow-y-auto sm:bg-eonx-neutral-50"
          :class="{
            'mr-5': !showSidebar,
          }"
          @scroll="onScroll"
        >
          <slot />
        </div>
        <chat-widget />
      </div>
      <template v-if="showSidebar">
        <sidebar-banners v-if="features.sidebarBanners" />
        <directory-section v-else />
      </template>
      <portal-target name="rec-sidebar" slim />
    </div>
    <chat-popup-keeper />
  </div>
</template>
