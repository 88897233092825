<script>
import ui from '/~/core/ui'
import ChatCreator from '/~rec/components/chat/chat-creator.vue'
import ChatRooms from '/~rec/components/chat/rooms/chat-rooms.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useChat } from '/~rec/composables/chat'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'recroom-chat-widget',
  components: {
    ChatCreator,
    ChatRooms,
    BaseIcon,
  },
  setup() {
    const { chat, hasUnreadMessages } = useChat()
    const { features, profile } = useRecProfile()

    return {
      chat,
      profile,
      hasUnreadMessages,
      features,
      ui,
    }
  },
  data() {
    return {
      opened: false,
      moving: false,
      touching: false,
      touchStartY: 0,
      lastTranslate: 0,
      minPossibleTranslate: 0,
      maxPossibleTranslate: 0,
    }
  },
  computed: {
    openThreshold() {
      return this.maxPossibleTranslate / 5
    },
    closeThreshold() {
      return (this.maxPossibleTranslate * 4) / 5
    },
    shouldOpenOnTouchEnd() {
      let threshold = this.openThreshold

      if (this.opened) {
        threshold = this.closeThreshold
      }

      return this.lastTranslate <= threshold
    },
    draggable() {
      return ui.mobile
    },
  },
  watch: {
    opened(value) {
      this.$emit('opened', value)
    },
  },
  mounted() {
    if (this.features.chat) {
      this.$el.style.transform = 'translateY(0px)'
    }
  },
  methods: {
    onClick() {
      if (!this.opened) {
        this.lastTranslate = -1
        this.opened = true
        this.maxPossibleTranslate =
          Math.floor(-this.$el.getBoundingClientRect().top) / 2

        this.$el.style.transform = 'translateY(-385px)'
      } else {
        this.lastTranslate = 0
        this.opened = false

        this.$el.style.transform = 'translateY(0px)'
      }
    },

    onTouchStart(e) {
      if (!this.draggable) return

      if (!this.opened) {
        this.maxPossibleTranslate = Math.floor(
          -this.$el.getBoundingClientRect().top
        )
        this.touchStartY = e.touches[0].clientY
      }

      this.touching = true
    },
    onTouchMove(e) {
      if (!this.draggable) return

      e.preventDefault()

      this.moving = true

      const { touchStartY, maxPossibleTranslate, minPossibleTranslate } = this
      const currentTouchY = e.touches[0].clientY
      const translateY = Math.max(
        Math.min(currentTouchY - touchStartY, minPossibleTranslate),
        maxPossibleTranslate
      )

      this.$el.style.transform = `translateY(${translateY}px)`
      this.lastTranslate = translateY
    },
    onTouchEnd() {
      if (!this.draggable) return

      this.touching = false

      if (this.moving) {
        this.moving = false
      } else return

      if (this.shouldOpenOnTouchEnd) {
        this.opened = true

        this.$el.style.transform = `translateY(${this.maxPossibleTranslate}px)`
      } else {
        this.opened = false
        this.touchStartY = 0
        this.lastTranslate = 0

        this.$el.style.transform = 'translateY(0px)'
      }
    },
  },
}
</script>

<template>
  <div
    v-if="features.chat"
    class="fixed top-11 right-0 z-popup mr-[35px] h-screen w-72 overflow-hidden rounded-t-xl bg-white shadow-sm"
    :class="{
      'ease transition duration-300': !touching,
    }"
    style="top: calc(100% - 48px)"
  >
    <div
      class="fixed z-20 flex h-12 w-full cursor-pointer select-none items-center justify-between rounded-t-xl bg-gray-700 px-5"
      @click="onClick"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd"
    >
      <div class="flex h-full items-center">
        <p class="font-bold text-white">Chats</p>
        <div
          v-if="hasUnreadMessages"
          class="mt-[5px] ml-[5px] self-start text-fg-error"
        >
          <base-icon class="block" svg="dot" size="xs" />
        </div>
      </div>

      <chat-creator color="white" icon-color="primary" />
    </div>

    <div
      class="mt-12 overflow-auto px-5"
      style="height: calc((100% / 2) - 48px)"
    >
      <chat-rooms />
    </div>
  </div>
</template>
